/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import { useWizard } from "react-use-wizard";

import styles from "../steps/Steps.module.scss";

import { calculatePrice, createCustomProduct, parseNameToImg } from "../../../helpers/helpers";
import { getImages } from "../../../helpers/images";

import { updateWizardData } from "../../../redux/actions/wizard";
import { Button } from "../../utils/ui/Button";
import { Cardinfo } from "../../utils/ui/Cardinfo";

import Details from "../aditionals/Details";
import Prices from "../../../helpers/prices.json";
import { addItemToCart } from "../../../redux/actions/cart";
import ProductModal from "../../utils/ProductModal";
import { useForm } from "../../../hooks/useForm";

export default function Step41({ executeScroll }) {
  const dispatch = useDispatch();
  const {
    actualStockTelaSelected,
    idTelaSeleccionada,
    fabricCost,
    step3Options,
    prenda,
    tipo,
    cuello,
    manga,
    tela,
    tela2,
    envio,
    precio,
    talla,
    tallaLabel,
    fruncido,
    personalizado,
    notas,
    observaciones,
    telaSeleccionada,
    telaSeleccionada2,
    idTelaSeleccionada2,
    actualStockTelaSelected2,
  } = useSelector((state) => state.wizard);

  const { previousStep } = useWizard();
  const [sleeves] = useState(getImages());

  const [formValues, handleInputChange] = useForm({
    obser: observaciones,
  });

  const { obser } = formValues;

  useEffect(() => {
    let _price = calculatePrice(prenda, tipo, manga, talla, Prices, personalizado);

    //validar si la tela tiene españa y si es asi , aumentearle 100
    if (tela.includes("ESPAÑA")) {
      _price += 100;
    }

    if (_price !== null) {
      dispatch(updateWizardData("precio", _price));
    } else {
      dispatch(updateWizardData("precio", 0));
      Swal.fire("", "Favor de seleeccionar un producto correcto.", "warning");
    }
  }, []);

  const handlesEndCraftProcess = () => {
    if (precio === 0 || precio === null) {
      Swal.fire("", "Favor de seleccionar un producto correcto.", "warning");
    } else {
      let myProduct = {
        prenda,
        tipo,
        cuello,
        manga,
        tela,
        tela2,
        tallaLabel,
        precio,
        fruncido,
        personalizado,
        notas,
        obser,
        otros: step3Options[prenda] && tipo !== "VESTIDO_CRUZADO" ? step3Options[prenda] : false,
        telaSeleccionada,
        fabricCost,
        idTelaSeleccionada,
        actualStockTelaSelected,
        telaSeleccionada2,
        idTelaSeleccionada2,
        actualStockTelaSelected2,
      };
      let customProduct = createCustomProduct(myProduct);
      dispatch(updateWizardData("observaciones", obser));
      dispatch(addItemToCart(customProduct));
    }
  };

  const returnStep = () => {
    dispatch(updateWizardData("talla", ""));
    dispatch(updateWizardData("tela", ""));
    dispatch(updateWizardData("cuello", ""));
    dispatch(updateWizardData("manga", ""));
    dispatch(updateWizardData("precio", 0));
    dispatch(updateWizardData("fabricCost", 0));
    executeScroll();
    previousStep();
  };

  return (
    <>
      <h2>Paso 4: Verifique cuidadosamente la prenda armanda.</h2>
      <hr />
      <div className="">
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <Details />
          </div>
          <div className="col-xs-12 col-md-3 material-container">
            <h3 className="spacing-mb-16">Estilo Seleccionado</h3>

            <hr />
            <div className="w-100">
              {tipo !== "VESTIDO_CRUZADO" && step3Options[prenda] ? (
                <img className="img-fluid selected-style" src={sleeves[`${tipo}_${cuello}`](`./${parseNameToImg(manga, true)}`)} alt={manga} />
              ) : (
                <img className="img-fluid selected-style" src={sleeves["OTROS"](`./${parseNameToImg(tipo)}`)} alt={`${tipo}`} />
              )}
            </div>
          </div>
          <div className="col-xs-12 col-md-3 tela-container">
            <h3 className="spacing-mb-16">Tela Seleccionada</h3>
            <hr />
            <div className="w-100">{tela !== "" && <img className="img-fluid material" src={telaSeleccionada} alt={`${tela}`} />}</div>

            {tipo === "VESTIDO_CRUZADO" && (
              <>
                <h3 style={{ marginTop: "20px" }}>Tela Seleccionada Para Olan</h3>
                <hr />
                <div className="w-100">{tela !== "" && <img className="img-fluid material" src={telaSeleccionada2} alt={`${tela}`} />}</div>
              </>
            )}
          </div>

          <div className="col-xs-12 col-md-3">
            <Cardinfo color="lightpeach" className="pricing-details d-flex justify-content-between align-items-center pricing-details-ends">
              <div>
                <p style={{ lineHeight: 0.5 }} className="body-large spacing-mb-18">
                  Precio:{" "}
                </p>
              </div>
              <div>
                <p style={{ lineHeight: 0 }} className="heading-3 font-500">
                  ${precio} MXN
                </p>
              </div>
            </Cardinfo>
            <div className="alert alert-info mt-3">Se aplicarán costes de envío al realizar el pago.</div>
          </div>
          <div className="col-xs-12 col-md-12">
            <label className={styles.label + " form-label mt-3"}>Observaciones:</label>
            <br />
            <textarea
              className={styles.input + " form-control"}
              name="obser"
              rows="4"
              value={obser}
              id="notes"
              onChange={handleInputChange}></textarea>
            {/*< div className="texto-informa texto-informa-full mt-2"
              <h4>
                Importante: Los tiempos de entrega comenzarán a partir de la primera semana de mayo.
                <br />
                Por favor, comuníquese con Ichi para obtener más detalles.
              </h4>
            </div>*/}
          </div>
          <div className="col-12">
            <div className="d-flex gap-3"></div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-start gap-3 ant-sig">
        <Button color="secondary" theme="outlined" onClick={() => returnStep()}>
          <i className="spacing-mr-8 fas fa-angle-double-left"></i> Anterior
        </Button>
        <Button color="secondary" onClick={() => handlesEndCraftProcess()}>
          Agregar al Carrito <i className="spacing-ml-8 fas fa-angle-double-right"></i>
        </Button>
      </div>
      <ProductModal wizard={true} />
    </>
  );
}
